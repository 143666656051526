
.navbar {
  z-index: 1;
  display: block;
  min-height: 0px;
}

.nav {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  li {
    z-index: 1;
    display: inline-block;
    width: auto;
    position: relative;
    a {
      display: inline-block;
      width: 100%;
    }

    ul {
      z-index: 2;
      position: absolute;
      top: 0px;
      left: 0px;
      display: none;
      li {
        width: 100%;
        a {
          width: 100%;
        }
      }
    }

    a:hover, a:focus, a:active {
      + ul {
        display: block;
      }
    }
    &:hover, &:focus, &:active {
      ul {
        display: block;
      }
    }

  }
}

#top-nav, #main-nav {
  font-family: @headings-font-family;
  text-transform: normal;
  border: 0px solid;
  position: relative;
  z-index: 6;
  > div.container {
    max-width:100%;
  }
}

#top-nav {
  background: @top-nav-bg;
  font-size: @top-nav-fszie;
  font-weight: @top-nav-fweight;
  font-family: @top-nav-font;
  color: @top-nav-color;
  border-bottom: @top-nav-border-color 1px solid;

  width:auto;
  text-align: @top-nav-align;
  .menu-top-menu-container {
    width: auto;
    vertical-align: middle;
    display: inline-block;
    float: none;
  }
  #menu-top-menu {
    width: auto;
    vertical-align: middle;
    display: inline-block;
    float: none;
  }
  ul.nav {
    vertical-align: bottom;
    li.active a {
    }
    li {
      &:first-child{ border-left:1px solid @top-nav-border-color}
      border-right: 1px solid @top-nav-border-color;
      border-top:0px;
      border-bottom:0px;
      float:left;
      a {
        display: inline-block;
        font-weight: inherit;
        font-size: inherit;
        font-family: inherit;
        color: @top-nav-color;
        padding: .5em 1.25em;

      }
    }
  }
  .social-links {
    vertical-align: bottom;
    display: inline-block;
    //margin-left: 1em;
    width:auto;
    font-size:1.25em;

    .social-link {

      background: #fff;
      color: @top-nav-social-color;

      border-right: 1px solid @top-nav-border-color;
      i {
        color: @top-nav-social-color;
      }

      &:hover, &:focus, &:active {
        //background: @top-nav-social-hover;
        //border-left-color: @top-nav-social-hover;
        //border-right-color: @top-nav-social-hover;
        &:before {
          //border-bottom-color: @top-nav-social-hover;
        }
        &:after {
          //border-top-color: @top-nav-social-hover;
        }
        i {
          color: @brand-primary;
        }
      }
    }
  }
}

#main-nav {
  z-index: 9;
  background: @main-nav-background;
  font-size: @main-nav-fsize;
  font-weight: @main-nav-fweight;
  font-style: @main-nav-fstyle;
  line-height: .9;
  position: relative;
  border-bottom:1px solid #aaa;
  @media (max-width: @screen-md-max) {
    font-size: @main-nav-fsize-small;
  }

  .brand-container {
    display: inline-block;
    vertical-align: middle;
    margin-right:30px;
    a.navbar-brand {
      display: inline-block;
      vertical-align: middle;
      padding: 0px;
      margin: 0px;
      #logo {
        height: @logo-height;
        width: @logo-width;
        position: relative;
        background: @header-logo-fallback;
        background: @header-logo;
        background-position: center center;
        background-size: cover;
        display: block;
        @media (max-width: @screen-md-max) {
          height: @logo-adjusted-height;
          width: @logo-adjusted-width
        }
        @media (max-width: @screen-sm-max) {
          height: @logo-mobile-height;
          width: @logo-mobile-width
        }
      }
    }
  }

  .navbar-collapse {
    position: relative;
    display: inline-block;
    > div {
      display: inline-block;
      > ul {
        vertical-align: middle;
        display: inline-block;
        position: relative;
        li a {
          display: inline-block;
          width: 100%;
        }
        > li {
          font-family: @main-nav-family;
          font-weight: @main-nav-fweight;
          font-style: @main-nav-fstyle;
          text-transform: @main-nav-ftransform;
          line-height: @main-nav-height;
          @media (max-width: @screen-md-max) {
            line-height: @main-nav-adjusted-height;
          }

          > a {
            line-height: 1;
            display: inline-block;
            vertical-align: middle;
            float: none;
            padding: @main-nav-top-level-padding;
            color: @main-nav-color;
            &:hover, &:focus, &:active {
              color: @main-nav-color-hover;
              &:before {
                color: @main-nav-color-hover;
              }
            }
          }
          &:hover > a, &:focus > a, &:active > a {
            color: @main-nav-color;
            &:hover, &:focus, &:active {
              color: @main-nav-color-hover;
            }
          }

          > ul {
            line-height:1.25em;
            border-top:5px solid @brand-primary;
            padding: @sub-menu-padding;
            background: @sub-menu-bg;
            font-size: @sub-menu-fsize;
            display: block;
            visibility: hidden;
            overflow: hidden;
            transform-origin: 50% 0;
            transform: rotateX(-90deg);
            transition: transform .25s ease-in-out;
            left: 50%;
            margin-left: @sub-menu-left;
            top: @sub-menu-top;
            width: @sub-menu-width;
            box-shadow: 1px 4px 3px rgba(0, 0, 0, .25);
            @media (max-width: @screen-md-max) {
              top: @sub-menu-adjusted-top;
              width: @sub-menu-width-adjusted;
              margin-left: @sub-menu-left-adjusted;
            }
            li {
              vertical-align: top;
              overflow: hidden;
              font-family: @sub-menu-family;
              font-weight: @sub-menu-fweight;
              border-top: 1px solid @sub-menu-seperator-color;
              &:first-child {
                border-top: 0px solid transparent;
              }

              a {
                overflow: visible;
                padding: @sub-menu-link-padding;
                color: @sub-menu-color;
                &:hover, &:focus, &:active {
                  color: @sub-menu-color-hover;
                }
                &:before {
                  transition: margin-left .5s;
                }
              }
              &:hover, &:focus, &:active {
                color: @sub-menu-color-hover;
                a {
                  color: @sub-menu-color-hover;
                }
              }

            }
          }

          &.split-column {
            > ul {
              @media(min-width: @screen-sm-min) {
                display: flex;
                align-items: stretch;
                flex-wrap: wrap;
              }
              width: (@sub-menu-width * 2);
              margin-left: (@sub-menu-left * 2);
              @media (max-width: @screen-md-max) {
                width: (@sub-menu-width-adjusted * 2);
                margin-left: (@sub-menu-left-adjusted * 2);
              }
              > li {
                width :50%;
                float: left;
                &:nth-child(2n+0){
                  border-right:1px solid @brand-primary;
                }
                &.full-sub-item {
                  border-right: 0px solid transparent;
                  text-align: center;
                  width: 100%;
                  font-weight: 600;
                  padding-left: 0px;
                  a:before {
                    display: none;
                  }
                }
                a:before {
                  //display: inline-block;
                  //width: .25em;
                  //height: 100%;
                  //
                  //background: @brand-primary;
                  //margin-right: .5em;
                  //content: '';
                }

              }
            }
          }
        }
        > li:hover ul, > li a:hover + ul, > li:focus ul, > li a:focus + ul > li:active ul, > li a:active + ul {
          visibility: visible;
          transform: rotateX(0deg);
        }
      }
    }
  }
}

