.history-page {.pt-image {
  width:100%;
  }
  }

.pt-image {
  display:inline-block;
  width:auto;
  max-width:100%;
  img {
    max-width:100%;
    }
  &.row {
    display:block;
    width:100%;
    text-align:center;
    }
  &.full-width {
    display:block; width:100%;
    text-align:center;
    img {
      display:inline-block;
      float:none;
      max-width:100%;
    }
  }
  &.circle-image {
    width:300px;
    height:0px;
    padding: 0px 10px 280px 10px;
    overflow:hidden;
    box-sizing: border-box;
    border: 10px solid #fff;
    border-radius: 50%;
    position: relative;
    img {
      position: absolute;
      height: 102%;
      top: -2px;
      width: auto;
      left: -2px;

    }
  }
}
img.alignright {
  float:right;
  margin-left:1em;
  display:inline-block;
}
img.alignleft {
  float:right;
  margin-right:1em;
  display:inline-block;
}
@media(max-width:@screen-md-min) {
  img.alignleft,img.alignright {
    &.size-full {
      float:none;
      display:block;
      }
    }
  }

@media(max-width:@screen-sm-min) {
  img.alignleft,img.alignright {
    display:block;
    float: none;
    margin-top:1em;
    margin-bottom:1em;
    max-width:100%;
    height:auto;
  }
}

img.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}



img.text-center { display: inline-block; margin:auto auto; }