







.heading-wrap {
  color: darken(@text-color, 10%);
  background: @basic-header;
  background-size: cover !important;
  background-position: bottom center !important;
  position: relative;

  @media (min-width: @screen-md-min) {
    display: flex;
    align-items: stretch;
  }

  h1 {line-height: 1em; }

  .heading-row > div {
    font-size:.75em;
    background: fade(@main-nav-background, 85%);
    padding:  3em  5em  3em  5em;
    float: none;
    border-left: 10px solid @brand-primary;
    .page-desc {
      font-size:1.25em;
      padding-left: .25em;
      padding-right: .25em;
    }
    @media (max-width: @screen-md-min) {
      font-size: .75em;
      padding: 1.5em;
    }
  }

  //.breadcrumb-row {
  //  a {
  //    color: @brand-primary;
  //    &:hover { color: ligthen(@brand-primary, 30%); }
  //  }
  //  .breadCrumb {
  //    margin-top: 2em;
  //    background: @brand-black;
  //    display: inline-block;
  //    padding: 10px;
  //  }
  //}
}

.single-format-standard {
  .heading-row {
    h1 {
      font-size: 2.5em;
    }
  }
}

.promoHeading.category .heading-wrap {
  min-height: 400px;
}