.live-area section div.gallery {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  > dl.gallery-item {
    width: 12.5% !important;
    @media(max-width:@screen-md-min) {
     width:25% !important;
    }
    height: auto;
    box-sizing: border-box;
    margin-top:5px !important;
    margin-bottom: 5px !important;
    padding:0px 5px;
    img {
      width:100%;
      height: auto;
      max-width: 150px;
    }
    .wp-caption-text.gallery-caption {
      display: none;
    }
  }
}