.headings-iterator(@weight,@n, @i: 1) when (@i =< @n) {
  h@{i} {
    font-weight: @weight;
  }
  .headings-iterator(@weight, @n, (@i + 1));
}

// Text Modifier Classes.
.weight-100 {
  .headings-iterator('100', 6);
}

.weight-300 {
  .headings-iterator('300', 6);
}

.weight-500 {
  .headings-iterator('500', 6);
}

.weight-900 {
  .headings-iterator('900', 6);
}

.text-xs {
  font-size: @text-xs;
  line-height: (@line-height-base);
}

.text-sm {
  font-size: @text-small;
  line-height: (@line-height-base);
}

.text-md {
  font-size: @text-reg;
  line-height: (@line-height-base);
}


.headings-line-height-reset{
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    &.text-xl,&.text-lg{
      line-height: @headings-line-height;
    }

  }
}
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  &.text-xl,&.text-lg{
    line-height: @headings-line-height;
  }

}


.text-lg {
  font-size: @text-large;
  line-height: (@line-height-base);
  @media (max-width: @screen-xs-max) {
    font-size: 1em;
  }
  @media (max-width: @screen-sm-max) {
    font-size: 1em;
  }
  @media (max-width: @screen-md-max) {
    font-size: 1.25em;
  }
  .headings-line-height-reset();
}

.text-xl {
  font-size: @text-xlarge;
  line-height: (@line-height-base);
  @media (max-width: @screen-xs-max) {
    font-size: 1.25em;
  }
  @media (max-width: @screen-sm-max) {
    font-size: 1.5em;
  }
  @media (max-width: @screen-md-max) {
    font-size: 1.75em;
  }

  .headings-line-height-reset();
}

.size-iterator(@n, @i: 2) when (@i =< @n) {
  .text-@{i}x {
    font-size: unit(@i, em);
  }
  .size-iterator(@n, (@i + 1));
}

.size-iterator(5);

.lightWeight {
  font-weight: 100;
}

.pumpSubhead {
  font-size: 2em;
  width: 100%;
  display: inline-block;
  @media (max-width: @screen-xs-max) {
    font-size: 1.15em;
  }
  @media (max-width: @screen-sm-max) {
    font-size: 1.35em;
  }
  @media (max-width: @screen-md-max) {
    font-size: 1.55em;
  }
}

//fix all paragraphs
p:last-child {
  margin-bottom: 0;
}

p > .btn {
  margin-top: (@grid-gutter-width /3.5);
  clear: both;
  display: inline-block;
}

.btn {
  margin-top: (@grid-gutter-width /3.5);
  font-weight: @btn-font-weight;
  margin-bottom: 1.5em;
}

h1, h2, h3, h4, h5, h6 {
  clear: both;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6, .btn {
  text-transform: Uppercase;
  letter-spacing: @letter-spacing-correction;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-left: @tex-indent-correction
}
h1, .h1 {
  text-transform: uppercase;
}
strong {
  font-weight: 700;
}

div {
  p  {
    &:last-child {
      margin-bottom: 0;
      &:first-child {
        margin-bottom: .3em;
      }
    }

  }
}
.live-area section { p  {
    &:last-child {
      margin-bottom: 2em;
      &:first-child {
        margin-bottom: 2em;
      }
    }

  }
}


.ul-before {
  position:relative;
  &:before {
    display: inline-block;
    position: absolute;
    line-height: 1;
    height: 1em;
    left : 0;
    width: 1em;
    //font-family: @brand-icon-font;
    content: '\2022';
    top: 0;
    margin-top: .5em;
    font-size: .75em;
    vertical-align: middle;
  }
}



.live-area {
  article {
    ul {
      list-style: none;
      padding-left: 0;
      font-size: 1.125em;
      margin-bottom: .5em;
      font-family: @headings-font-family;
      font-weight: 400;
      /*     text-transform: uppercase; */
      li {
        padding: .125em;
        padding-left: 1.125em;
        .ul-before();
      }
      + .btn {
        margin-top: .5em;
      }
    }
  }
}


section > div.container {
  max-width:100%;
}

a.fulldiv-link {
  color: inherit;
  &:hover {
    color: inherit;
  }
}

blockquote {
  padding: (@line-height-computed / 2) @line-height-computed;
  margin: 0 0 @line-height-computed;
  font-size: @blockquote-font-size;
  border-left: 5px solid @blockquote-border-color;
  p {
    &:last-child{
      font-size:.75em;
      font-style: italic;
      &:only-child {
        font-size: 1em;
        font-style: normal;
      }
    }
  }
}

hr {
  margin-top: 1.3em;
  margin-bottom: 1.3em;
  border: 0;
  border-top: 1px solid @text-color;
}
hr {
  border-top: 1px solid @text-color;
}
h1, .h1,
h2, .h2,
h3, .h3 {
  margin-top: .7em;
  margin-bottom: .35em;

  border-bottom:1px solid @brand-primary;
  width:100%;
  display:inline-block;
  padding-bottom: .35em;

}
.bg-primary {
  h1, .h1,
  h2, .h2,
  h3, .h3 {
    border-color: #fff !important;
  }
}

i.icon {
  h1, .h1,
  h2, .h2,
  h3, .h3 {
    margin-top:.5em;
  }
}



h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: .7em;
  margin-bottom: .7em;
}

p {
  margin: 0 0 1.25em;
}





.next-prev-links {
  border-top: 1px solid @text-color;


  a.btn{

    margin:20 0 20 0;
    display: inline-block;
  }

}



.center-xs {
  @media(max-width: @screen-xs-max) {
    .column {
      text-align: center;
      ul {
        display:inline-block;
        text-align:left;
        width:auto;
      }
    }
  }
}
.center-sm {
  @media(max-width: @screen-sm-max) {
    .column {
      text-align: center;
      ul {
        display:inline-block;
        text-align:left;
        width:auto;
      }
    }
  }
}
.center-md {
  @media(max-width: @screen-md-max) {
    .column {
      text-align: center;
      ul {
        display:inline-block;
        text-align:left;
        width:auto;
      }
    }
  }
}
.center-lg {
  @media(min-width: @screen-lg-min) {
    .column {
      text-align: center;
      ul {
        display:inline-block;
        text-align:left;
        width:auto;
      }
    }
  }
}

p:empty {
  display: none;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  animation-name: pulse;
}



.LANDING-PAGE, section.landing-page, .landing-page {
  h1, .h1,
  h2, .h2,
  h3, .h3 {
    margin-top: .35em;
    margin-bottom: .35em;
    border-bottom:0 solid @brand-primary;
    width:100%;
    display:inline-block;
    padding-bottom:0em;
    &.lightWeight {
      font-weight: 400;
      strong {
        font-weight: 700;
      }
    }
    strong {
      font-weight: 400;
    }
  }
  .btn {

    max-width: 100%;
    white-space : normal;
    word-break: normal;
    &:hover,&:focus,&:active{
      animation: pulse  0.5s;



    }
  }
  .btn:only-child{
    margin-top: 0 !important;
  }
}
.para-correction {
  p:last-child {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
}


.border-bottom-strong {
  border-bottom: 15px solid @brand-primary;
}