// Global template background fixes.
section {
  background-size: cover;

}

// fixes section background default.
// Required Repeat Fixes

.repeat-fixes(@primary-color,@text-color, @link-color) {
  h1, h2, h3, h4, h5, h6, p, li {
    color: @text-color;
  }
  i,span.b-icon { color: @primary-color; }
  a {&:hover {
    text-decoration: underline; } }

  ul {
    li {
      a {color: @link-color;}
    }
    li:before {
      color: @brand-primary;
    }
  }
  hr {
    border-top-color:@text-color ;
  }
}

.repeat-fixer(@primary-color, @text-color, @link-color) {
  h1, h2, h3, h4, h5, h6, p, li {
    color: @text-color;


  }
  i,span.b-icon { color: @text-color; }
  a {
    &:hover {
    text-decoration: underline; } }

  ul {
    li {
      a {color: @link-color;}
    }
    li:before {
      color: @brand-primary;
    }
  }
  hr {
    border-top-color:@text-color ;
  }
}





// Global Background-mixins ----------------------------------------------------------------------------------------

// used to add bg color modifiers
.bg-variants(@color, @text-color, @link-color) {
  border-color: @link-color;
  background: @color;
  color: @text-color;
  .hr-row {
    .column {
      //border-color: @text-color;
    }
  }
  .repeat-fixer(@color,@text-color, @link-color);
  .btn {
    /// special defaults
    text-decoration: none;
    //&:not(.btn-line){color:@color;}

     &:hover { text-decoration:none;}
    i,span.b-icon { color: inherit !important;  }
    &.btn-default  {
      color: @color;
      background-color: @text-color;
      outline-color: @text-color;
      border-color: @text-color;
      &:hover, &:focus {
        color: @text-color;
        background: @color;
        border: 1px solid @text-color;
        &:before {
          color: @color;
        }
        &:after {
          color: @color;
        }
      }
    }


    //&.btn-default.btn-line {
    //  color: @text-color;
    //  background-color: transparent;
    //  border-color: @text-color;
    //
    //  &:before {
    //    color: @text-color;
    //  }
    //  &:hover, &:focus {
    //    color: @text-color;
    //    background-color: fade(@text-color, 10);
    //
    //    &:before {
    //      color: @text-color;
    //
    //    }
    //    &:after {
    //      color: @text-color;
    //    }
    //  }
    //}

  }
}

// used to add support for mask style color overlays on images
.bg-variants-image(@color; @text-color;  @overlaycolor; ) {
  z-index: 2;
  border-color: @text-color;
  color: @text-color;
  .hr-row {
    .column {
      //border-color: @text-color;
    }
  }
  &:before {
    z-index: -1;
    position: absolute;
    background: @overlaycolor;
    opacity: .75;
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    left: 0;
    top: 0;
  }
  .repeat-fixer(@color,@text-color,@text-color);
  .btn {
     text-decoration: none;
     &:hover { text-decoration:none;}
    i,span.b-icon { color: inherit !important;  }
    /// special defaults
    //color:@color;
    &.btn-default {
      color: @color;
      background-color: @text-color;
      outline-color: @text-color;
      border-color: @text-color;
      &:hover, &:focus {
        color: @text-color;
        background: @color;
        border: 1px solid @text-color;
        &:before {
          color: @color;
        }
        &:after {
          color: @color;
        }
      }
    }


    //&.btn-default.btn-line {
    //  color: @text-color;
    //  background-color: transparent;
    //  border-color: @text-color;
    //
    //  &:before {
    //    color: @text-color;
    //  }
    //  &:hover, &:focus {
    //    color: @text-color;
    //    background-color: fade(@text-color, 10);
    //
    //    &:before {
    //      color: @text-color;
    //
    //    }
    //    &:after {
    //      color: @text-color;
    //    }
    //  }
    //}
    &.btn-primary {
      color: @text-color;
      &:hover, &:focus {
        background: @brand-primary;
        color: @text-color;
        border-color: @text-color;
        border: 1px solid @text-color;

        &:before {
          color: @text-color;
        }
        &:after {
          color: @text-color;
        }
      }
    }




  }
}



// Global Background-mixins  END ----------------------------------------------------------------------------------------

i,span.b-icon { color: @brand-primary; }
.btn {
  i,span.b-icon { color: inherit;  }
}
ul {
  li {
    a {
      color: @text-color;
    }
  }
}






// BG MIXIN CALLS ----------------------------------------------------------------------------------------------------------

// bg-variants-image(@color; @text-color;)
// color is used to define background color overlay and text color is used to define inhereted text color.
.bg-full-overlay-dark {
  .bg-variants-image(@brand-gray-dark, @brand-text-on-dark,@brand-gray-dark);
}

.bg-full-overlay-light {
  .bg-variants-image(@brand-text-on-dark, @brand-gray-dark,@brand-text-on-dark);
}

.bg-image-dark {
  @local: rgba(0, 0, 0, 0);
  .bg-variants-image(@brand-gray-dark, @brand-text-on-dark, @local);
}

.bg-image-light {
  @local: rgba(0, 0, 0, 0);
  .bg-variants-image(@brand-text-on-dark, @brand-gray-dark, @local);
}

.bg-image-dark-overlay {
  @local: @brand-gray-dark;
  .bg-variants-image(@brand-gray-dark, @brand-text-on-dark, @local);
}

.bg-image-light-overlay {
  @local: @brand-text-on-dark;
  .bg-variants-image(@brand-text-on-dark, @brand-gray-dark, @local);
}

.bg-light-overlay {
  @local: @brand-text-on-dark;
  .bg-variants-image(@brand-text-on-dark, @brand-gray-dark, @local);
  &.column {
    border-left: 5px solid @brand-primary;
    padding-left: 30px;
    padding-right:30px;
  }

}

.bg-dark-overlay {
  @local: @brand-gray-dark;
  .bg-variants-image(@brand-gray-dark, @brand-text-on-dark, @local);
  &.column {
    border-left: 5px solid @brand-primary;
    padding-left: 30px;
    padding-right:30px;
  }
}

// bg-variants(@color; @text-color; @modifier;)
// color describes background color textcolor describes text color this modifies links as well as link styles.
// modifier is proposed manipulator to darken or lighten of hover styles all hard coded for right now.

.bg-white {
  .bg-variants( #fff,@brand-black, @brand-primary);
}
.bg-black {
  .bg-variants(@brand-black, @brand-text-on-dark, @brand-primary);
}

.bg-gray {
  .bg-variants(@brand-gray-dark, @brand-text-on-dark, @brand-primary );
}



.bg-white-clip {
  .bg-variants(#fff, #000,@brand-primary) ;
  background-clip: padding-box;
  border-left: (@grid-gutter-width/2) solid transparent;
  border-right: (@grid-gutter-width/2) solid transparent;
  padding-top: (@grid-gutter-width/2);
  padding-bottom: (@grid-gutter-width/2);
}
 
.bg-primary {
  .bg-variants(@brand-primary, #fff,@brand-gray-dark);
}
.bg-gray-light {
  .bg-variants(@brand-gray-light, @brand-black, @brand-primary );
}



//BACKGROUND MODIFIER CLASSES ----------------------------------------------------------------------------------------


.bg-center {
  background-position: center center !important;
}
.bg-repeat-vert {
  background-size: 100%;
  background-repeat: repeat-y;
}

.bg-cover {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-half-primary-gray {
  z-index: 1;
  position: relative;
  &:before,&:after {
    width: 50%;
    z-index: -1;
    display: block;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;

  }
  &:before {
    background: @brand-primary;
    left: 0;
  }
  &:after {
    background: @brand-gray-light;
    right: 0;

  }

  @media( max-width: @screen-sm-max) {
    .row .column ~ .column{
      margin-top: 0px;
      margin-bottom: 0px;
    }
    &:before,&:after {
      width: 100%;
      height: 50%;
      z-index: -1;
      display: block;

      content: '';
      position: absolute;
      top: initial;
      left: 0;
    }
    &:before {
      background: @brand-primary;
      top: 0px;
    }
    &:after {
      background: @brand-gray-light;
      top: 50%;

    }

  }
}
