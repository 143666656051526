// GRAVITY FORMS


.gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_first, .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_last, .gform_wrapper.gf_browser_chrome ul.gform_fields li.gfield span.name_middle {
  padding-top : 0px;
  }

.gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=email], .gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=number], .gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=password], .gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=tel], .gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=text], .gform_wrapper .gfield_error .ginput_complex .ginput_right input[type=url], .gform_wrapper .ginput_complex input[type=email], .gform_wrapper .ginput_complex input[type=number], .gform_wrapper .ginput_complex input[type=password], .gform_wrapper .ginput_complex input[type=tel], .gform_wrapper .ginput_complex input[type=text], .gform_wrapper .ginput_complex input[type=url] {
  }

.gform_wrapper ul li.gfield {
  clear : none !important;
  }

.gform_wrapper .gform_footer {
  width   : 100%;
  padding : 1em 1em;
  }

.gform_fields {
  list-style   : none;
  padding-left : 0px;
  }

//GLOBAL GFORMS STYLES

.gform_wrapper {
  margin-right : 0px;
  max-width    : 100%;

  ul li hr {
    padding: 0px 0px 0px 0px !important;
    margin-top:.5em !important;
    margin-bottom: .5em !important;
  }
  .gform_body ul li {
    vertical-align: top;
    padding-left: 15px;
    padding-right:15px;
    &:before {
      content: '';
      display:none;
    }
  }
  .gform_body {
    hr {
      + h1,+  h2,+  h3,+  h4,+  h5,+  h6 {
        margin-top : 0.325em;
        }
      }
    h1, h2, h3, h4, h5, h6 {
      font-weight : @headings-font-weight;
      //border-bottom  : 1px solid @brand-gray;
      font-size   : 1.25em;

      + p {
        margin-top    : .5em;
        margin-bottom : 1em;
        }
      &.gsection_title {
        display : none;
        }
      }
    //margin-left  : (@grid-gutter-width * -0.5);
    //margin-right : (@grid-gutter-width * -0.5);
    ul.gform_fields {
      margin-left: 0px;
      li.field_admin_only{
        display:none;

      }
      }
    ul.top_label {
      display : inline-block;
      //border  : 1px solid @brand-black;
      padding-bottom: 1em;
      margin-left:0px;

      li {
        width: 100%;
          &.xs-half { width: 50%; }
          &.xs-third { width: 33.3333%; }
          &.xs-quarter { width: 25%; }
          &.xs-fifth { width: 20%; }
        @media(min-width: @screen-sm-min) {
          &.sm-half { width: 50%; }
          &.sm-third { width: 33.3333%;}
          &.sm-quarter { width: 25%; }
          &.sm-fifth { width: 20%; }
        }
        @media(min-width: @screen-sm-max) {
        &.half {
          width:50%;
        }
        &.third {
          width:33.3333%;
        }
        &.quarter{
          width:25%;
        }
          &.fifth { width: 20%; }
          p {
            font-family: @font-family-base;
            margin-top:.5em;
            margin-bottom: .5em;
          }
        }

        &.clear-div {
          display:inline-block;
          width:100%;
          padding-bottom:1em;
          }
        &.sm-clear-div {
          display:none !important;
          @media(max-width: @screen-sm-max) {
            display:inline-block !important;
            width:100%;
            padding-bottom:1em;
            }
          }
        display    : inline-block;
        margin-top : .5em;
        &.gfield.gsection {
          margin-top    : 0px;
          margin-bottom : .5em;
          float         : left;
          width         : 100%;
          display       : inline-block;
          border-bottom : 0px;
          h2 {
            margin-left  : (@grid-gutter-width * 0.5) !important;
            margin-right : (@grid-gutter-width * 0.5) !important;

            }
          }
        img.img-responsive {
          max-width: 100%;
          border-right: 1px solid #000;
          padding:1em;
          }
        hr {
          margin-top    : 1em;
          margin-bottom : 1em;
          padding-top:0px;
          padding-bottom: 0px;
          padding-top:0em;
          padding-bottom: 0em;
          }
        &.no-min-height {
          }
        &.gfield_html {
          }
        &.clear-left {
          }
        ul.gfield_checkbox {
          padding-left : 0px;
          }
        .ginput_container_checkbox {
          margin-top : .5em;
          li {
            margin-bottom : 0px;
            }
          }
        input,textarea,select {

          &.large {
            width:100%;
            }
          }
        .datepicker {
          width: 100%;
        }
        .ginput_container_number {
          width : 150px;
          &:after {
            display     : inline-block;
            content     : 'QTY';
            margin-left: 1em;
            font-size   : 1em;
            font-weight : normal;
            }
          input { width : 50px; }
          }
        label.gfield_label + div.ginput_container {
          }
        label {
          font-family    : @headings-font-family;
          //text-transform : uppercase;
          font-size      : 1em;
          margin-bottom:.25em;
          }
        span label {
          font-size: 1em;
        }
        input {
          &:focus {
            outline    : none;
            box-shadow : none;
            &::-webkit-input-placeholder {
              color : #aaa;
              }
            &::-moz-placeholder {
              color : #aaa
              }
            &:-moz-placeholder {
              color : #aaa
              }
            &:-ms-input-placeholder {
              color : #aaa
              }
            }
          &::-webkit-input-placeholder {
            color : #aaa;
            }
          &::-moz-placeholder {
            color : #aaa
            }
          &:-moz-placeholder {
            color : #aaa
            }
          &:-ms-input-placeholder {
            color : #aaa
            }
          }
        select {
          padding   : .25em;
          font-size : 1em;
          }
        input[type="text"] {
          font-family: @font-family-base;
          font-weight: 400;
          font-style: normal;
          padding   : .25em;
          font-size : 1em;
          &:focus {
            }
          }
        input[type="checkbox"], input[type="radio"] {
          margin-top:0px;
          + label {
            margin-bottom: 0;
            font-size: .8em;
            margin-left: .25em;
            white-space: nowrap;
            font-family: @font-family-base;
            font-weight: 400;

            }
          }
        .ginput_container_radio {
          margin-top:0px;
        }
        ul.gfield_radio {
          li {
            padding-left:0px;
            display: inline-flex;
            width: auto;
            float:none;
            align-items: center;
            margin-top:3px;
            margin-bottom:3px;
            margin-right:20px;
          }
        }
        input[type="checkbox"], input[type="radio"] {
          }
        textarea {
          font-family: @font-family-base;
          font-weight: 400;
          font-style: normal;
          padding   : .25em;
          font-size : 1em;
          }
        .gfield_checkbox {
          li {
            }
          }
        span {
          }
        div.ginput_container {
          }
        &.hide-sub {
          span label {
            display : none;
            }
          }

        .gfield_html.gfield_no_follows_desc {
          }

        ul.gfield_radio li {
          @media (max-width : 768px) {
            width : 100% ;
            }
          }
        &.gfield_error {
          background-color: inherit;
                  margin-bottom: 0px !important;
          border-top:0px;
          border-bottom: 0px;
          padding-top: 0px !important;
          .ginput_container {
            margin-top: 8px !important;

          }
          label.gfield_label {
            margin-top: 0px !important;
          }

          input {
            border: @brand-primary;
            }
          }
        }
      .gfield_description {
        margin-top: 10px;
        padding-top:0px;
        padding-right: 0px;
        font-weight: 400;
        font-family: @font-family-base;
        }
      .gfield_description.validation_message {
        font-size   : .75em;
        font-family : @headings-font-family;
        font-weight : @headings-font-weight;
        display     : inline-block;
        .bg-primary();
        margin-top  : .5em;
        padding     : .125em .5em;
        width       : auto;

        }

      .main-selection {

        display        : inline-block;
        vertical-align : middle;
        position       : relative;
        label {
          font-size      : 2.5em;
          line-height    : 1em;
          font-weight    : 900;
          font-family    : @headings-font-family;
          display        : inline-block;
          vertical-align : middle;
          margin-right   : .5em;
          margin-bottom  : 0px;
          .gfield_required {
            display : none;
            }
          }
        select {
          font-size      : 1.5em;
          vertical-align : middle;
          }
        .ginput_container_select {
          display       : inline-block;
          margin-bottom : 10px;
          }
        }

      }
    .gform_hidden {
      label {
        display : none;
        }
      }
    }

  div.instruction.validation_message {
    }
  div.validation_error {
    font-size      : 1.25em;
    font-family    : @headings-font-family;
    font-weight    : @headings-font-weight;
    .bg-primary();
    //text-transform : uppercase;
    padding        : 1em;
    margin-bottom  : 0em;
    }

  .btn.btn-default {
    margin-top:0px;
    }
  .gform_title {
    margin-bottom: .5em;
    }
  .instruction.validation_message {
    font-size:.75em;
    margin-top:.35em;
    color: @brand-danger;
    display : none;
    }

  .gform_footer {
    padding-bottom : 0px;
    padding-top    : 0px;
    }



  .ginput_complex {
    &.has_street {
      span { padding-rigth: 1em; width: 100%; }


      @media(min-width: @screen-sm-max){
        span:nth-child(1) {width:100%; padding-right:0px; input { width:100%;}}
        span:nth-child(2) {width:100%; padding-right:0px; input { width:100%;}}
        span:nth-child(n+3) { width:25%; input,select { width:100%;} }
        span:last-child { padding-right:0px; }
      }
    }
    span {
      width: auto;
      padding-right:15px;
      padding-bottom:15px;
      margin-right:0px;
      margin-bottom:0px;
      display:inline-block;
      label {
        font-weight: 400;
        width:auto;
        float:none;
        clear:both;
        display:block;
      }
      input, select {
        float:none;
      }
    }
  }

  .gform-body {
    > ul.gform_fields  > li > label {
      font-size: 1.125em;
    }
  }
}





.gform_footer {
  //input[type="submit"] {
  //  display : none;
  //  }
  }





























.border-box {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
}



.gform_wrapper  {
  form.form-flex {
    .gform_body {
      ul.gform_fields.top_label  {
        display: flex;
        padding-bottom: 0px;
        align-items: flex-end;
        li {
          padding: 0px 0px 0px 0px;
          margin: 0px 0px 0px 0px;
          margin-right:10px;
          label {
            font-size: 1em;
            font-weight: 400;
            margin: 0px 0px 0px 0px;
            margin-bottom:5px;
            + div {
              margin-top: 0px;
            }
          }
          button {
            margin: 0px 0px 0px 0px;
          }
        }
      }
    }
    .gform_footer {
      padding:0px 0px 0px 0px !important;
      margin: 0px 0px 0px 0px !important;

    }
  }

}
