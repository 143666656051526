
  // do find and replace for .icon-i- and replace with *.

  // regext this .icon-i_\S+\s+\{\s+\S+\s+\S+\s+\S+\s+\S+\s+\S+\s+
  // than this \{\s+\s+\S+\s+}
  // than this &.(\S+) with 'b-icon {$1}' => '{$1}',



i.b-icon, span.b-icon {
    font-family:"ICO";
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1em;
    width:auto;
    position:relative;
    height:auto; 
    margin-bottom:.25em;
  &:before {
    line-height : 1em;
    font-size:1em;
    font-family:"ICO";
    display:inline-block;


  }


  &.bicon-01:before {
    content: "\e900";
  }
  &.bicon-01:before {
    content: "\e900";
  }
  &.bicon-02:before {
    content: "\e901";
  }
  &.bicon-03:before {
    content: "\e902";
  }
  &.bicon-04:before {
    content: "\e903";
  }
  &.bicon-05:before {
    content: "\e904";
  }
  &.bicon-06:before {
    content: "\e905";
  }
  &.bicon-07:before {
    content: "\e906";
  }
  &.bicon-08:before {
    content: "\e907";
  }
  &.bicon-09:before {
    content: "\e908";
  }
  &.bicon-10:before {
    content: "\e909";
  }
  &.bicon-11:before {
    content: "\e90a";
  }
  &.bicon-12:before {
    content: "\e90b";
  }
  &.bicon-13:before {
    content: "\e90c";
  }
  &.bicon-14:before {
    content: "\e90d";
  }
  &.bicon-15:before {
    content: "\e90e";
  }
  &.bicon-16:before {
    content: "\e90f";
  }
  &.bicon-17:before {
    content: "\e910";
  }
  &.bicon-18:before {
    content: "\e911";
  }
  &.bicon-19:before {
    content: "\e912";
  }
  &.bicon-20:before {
    content: "\e913";
  }
  &.bicon-21:before {
    content: "\e914";
  }
  &.bicon-22:before {
    content: "\e915";
  }
  &.bicon-23:before {
    content: "\e916";
  }
  &.bicon-24:before {
    content: "\e917";
  }
  &.bicon-25:before {
    content: "\e918";
  }
  &.bicon-26:before {
    content: "\e919";
  }
  &.bicon-27:before {
    content: "\e91a";
  }
  &.bicon-28:before {
    content: "\e91b";
  }
  &.bicon-29:before {
    content: "\e91c";
  }
  &.bicon-30:before {
    content: "\e91d";
  }
  &.bicon-31:before {
    content: "\e91e";
  }
  &.bicon-32:before {
    content: "\e91f";
  }
  &.bicon-33:before {
    content: "\e920";
  }
  &.bicon-34:before {
    content: "\e921";
  }
  &.bicon-35:before {
    content: "\e922";
  }
  &.bicon-36:before {
    content: "\e923";
  }
  &.bicon-37:before {
    content: "\e924";
  }
  &.bicon-38:before {
    content: "\e925";
  }
  &.bicon-39:before {
    content: "\e926";
  }
  &.bicon-40:before {
    content: "\e927";
  }
  &.bicon-41:before {
    content: "\e928";
  }
  &.bicon-42:before {
    content: "\e929";
  }
  &.bicon-43:before {
    content: "\e92a";
  }
  &.bicon-44:before {
    content: "\e92b";
  }
  &.bicon-45:before {
    content: "\e92c";
  }
  
}

.size-iterator-icon(@n, @i: 2) when (@i =< @n) {
  .b-icon.icon-@{i}x { font-size: unit(@i,em);
  }
  .size-iterator-icon(@n, (@i + 1));
}
.size-iterator-icon(5);