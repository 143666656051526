// test update for git.
// test update 2 from php storm for git.
@testvar : "asdfs";
//---------------------------------------------------------
//   BEGIN DOCUMENT HANDLING
//---------------------------------------------------------

@gray-base: #000001;
@gray-darker:            lighten(@gray-base, 13.5%); // #222 asdf
@gray-dark:              lighten(@gray-base, 20%);   // #333
@gray:                   lighten(@gray-base, 33.5%); // #555
@gray-light:             lighten(@gray-base, 46.7%); // #777
@gray-lighter:           lighten(@gray-base, 93.5%); // #eee

@brand-success: #c5f12c;
@brand-info:            #5bc0de;
@brand-warning: #f98521;
@brand-danger: #720007;

@brand-primary: #cc4514; // #fad72c
@brand-secondary: #08292e;
@brand-black: #08292e;
@brand-gray-dark : #08292e;
@brand-gray-light : #d7e3e3;
@brand-text-on-dark: #fff;


//defines the half and half isntance colors
@input-color: @brand-gray-dark;



@padding-increment: 1.5em;


// for border left border top classes
@border-color : @brand-primary;

@brand-icon-font: 'ICO';


//---------------------------------------------------------
//        Mobile Menu Variables
//---------------------------------------------------------


@menu-background: #fff;
@menu-color: @brand-secondary;
@transTime : .125s;
@mobile-item-padding: .5em;
@scrollLockColor: @brand-black;

//---------------------------------------------------------
//        Desktop Menu variabes
//---------------------------------------------------------

@top-nav-color : @brand-primary;
@top-nav-border-color: #aaa;
@top-nav-fszie : .75em;
@top-nav-bg: #fff;
@top-nav-font : @font-family-base;
@top-nav-fweight : 400;
@top-nav-align : right;
@top-nav-social-color: #aaa;
@top-nav-social-hover: @brand-primary;



@basic-header: url(../images/basic-header.jpg);
@header-logo: url('../images/logo.svg');
@header-logo-fallback: url('../images/logo3x.png');
@header-logo-color-fallback: fade(@brand-gray-dark,0%);
@logo-height: 100px;
@logo-width: 266px;
@logo-adjusted-height: (@logo-height * .75);
@logo-adjusted-width: (@logo-width * .75);
@logo-mobile-height: (@logo-height * .75);
@logo-mobile-width: (@logo-width * .75);

@main-nav-family : @headings-font-family;
@main-nav-fsize: .9em;
@main-nav-fsize-small: .8em;
@main-nav-fstyle: inherit;
@main-nav-ftransform: normal;
@main-nav-fweight: 400;
@main-nav-height: @logo-height;
@main-nav-li-before-content: '|';


@main-nav-adjusted-height: @logo-adjusted-height;
@main-nav-background : #fff;
@main-nav-color: @brand-secondary;
@main-nav-color-hover: @brand-primary;
@main-nav-top-level-pad: 1.25em;
@main-nav-top-level-padding: @main-nav-top-level-pad @main-nav-top-level-pad @main-nav-top-level-pad @main-nav-top-level-pad;
@perpval : 1000px; // determines perspecitve of transform

@sub-menu-family: @headings-font-family;
@sub-menu-top: @main-nav-height;
@sub-menu-left: (@sub-menu-width * -.45); 
@sub-menu-adjusted-top: @main-nav-adjusted-height;
@sub-menu-padding : 0em 0em;
@sub-menu-fsize: 1em;
@sub-menu-fweight: 400;
@sub-menu-width: 250px;
@sub-menu-width-adjusted: 225px;
@sub-menu-left-adjusted: (@sub-menu-width-adjusted * -.45);

@sub-menu-transition-max-height : 300px;
@sub-menu-bg: #fff;
@sub-menu-color: @brand-secondary;
@sub-menu-color-hover: @brand-primary;
@sub-menu-link-padding: 1.5em 2em;
@sub-menu-seperator-color: #aaa;
@sub-menu-transeffect-color: @brand-text-on-dark;


//---------------------------------------------------------
//        Footer variabes
//---------------------------------------------------------

@footer-color-2 : #fff;
@footer-color-1: #fff;
@footer-hover: @brand-primary;

@footer-before-text: @brand-gray-dark;
@footer-text:  @brand-gray-dark;


@footer-logo: url('../images/logo.svg');
@footer-logo-fallback: url('../images/logo3x.png');
@footer-logo-color-fallback: #fff;
@footer-logo-height: 96px;
@footer-logo-width: 175px;


//---------------------------------------------------------
//        Form variabes
//---------------------------------------------------------

@form-color : @brand-black;
@form-border : @brand-black;
@form-background : @brand-primary;





// TRIANGLE SIZE IN BUTTON
@tri-width: .5em;

//padding-size variables

@xs-pad : .5;
@sm-pad : 1;
@md-pad : 1.25;
@lg-pad : 1.5;
@xl-pad : 1.75;
@no-pad : 0;
@reg-pad : .5;




//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
@body-bg:               #fff;
//** Global text color on `<body>`.
@text-color:            #385f65;

//** Global textual link color.
@link-color:            @brand-primary;
//** Link hover color set via `darken()` function.
@link-hover-color:      darken(@link-color, 15%);
//** Link hover decoration.
@link-hover-decoration: none;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.


@font-family-sans-serif:  'Poppins', Helvetica, Arial, sans-serif;
@font-family-serif:       'Poppins', Helvetica, Arial, sans-serif;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
@font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;


@font-size-base:          1em;
@font-size-large:         (@font-size-base * 1.125); // ~18px
@font-size-small:         (@font-size-base * 0.875); // ~12px

@font-size-h1:            (@font-size-base * 3); // ~36px
@font-size-h2:            (@font-size-base * 2); // ~30px
@font-size-h3:            (@font-size-base * 1.5); // ~24px
@font-size-h4:            (@font-size-base * 1.25); // ~18px
@font-size-h5:            (@font-size-base * 1.125);
@font-size-h6:            @font-size-base; // ~12px

//** Unit-less `line-height` for use in components like buttons.
@line-height-base:        1.71em; // 20/14
@btn-ine-height-base : 1em;
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
//@line-height-computed:    floor((@font-size-base * @line-height-base)); // ~20px
@line-height-computed:    1.3em; // ~20px

//** By default, this inherits from the `<body>`.
@headings-font-family:    'Poppins', Helvetica, Arial, sans-serif;
@headings-font-style: normal;
@headings-font-weight:    700;
@headings-line-height:    .95em;
@headings-color:          inherit;
@headings-light-weight: 100;


// SETS DEFAULT SIZE THAT ALL EMS ARE BASED UPON.
@base-em-size: 14px ;



// text - size modidfers these are wrapping classes that balloon all text sizes relatively within them.
@text-xs: .5em;
@text-small: .875em;
@text-reg: 1rem;
@text-large: 1.25em;
@text-xlarge: 1.5em;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.
@btn-font-family: @headings-font-family;
@btn-font-weight:                700;

//@btn-default-color:              #fff;
//@btn-default-bg:                 #000;
//@btn-default-border:             #000;
//@btn-default-line-color:             #000;


//@btn-primary-color:              #fff;
//@btn-primary-bg:                 @brand-primary;
//@btn-primary-border:             darken(@btn-primary-bg, 5%);

@btn-success-color:              #fff;
@btn-success-bg:                 @brand-success;
@btn-success-border:             darken(@btn-success-bg, 5%);

@btn-info-color:                 #fff;
@btn-info-bg:                    @brand-info;
@btn-info-border:                darken(@btn-info-bg, 5%);

@btn-warning-color:              #fff;
@btn-warning-bg:                 @brand-warning;
@btn-warning-border:             darken(@btn-warning-bg, 5%);

@btn-danger-color:               #fff;
@btn-danger-bg:                  @brand-danger;
@btn-danger-border:              darken(@btn-danger-bg, 5%);

@btn-link-disabled-color:        @gray-light;



@btn-brand-color:               #fff;
@btn-brand-line-color:          @brand-primary;
@btn-brand-bg:                  @brand-primary;
@btn-brand-border:              @brand-primary;
@btn-brand-beforecolor:         darken(@brand-primary,40%);


@btn-default-color:              #fff;
@btn-default-bg:                 @brand-gray-dark;
@btn-default-border:             @brand-gray-dark;
@btn-default-line-color:         @brand-gray-dark;
@btn-default-beforecolor :  #fff;




@btn-secondary-color:               #fff;
@btn-secondary-line-color:          @brand-secondary;
@btn-secondary-bg:                  @brand-secondary;
@btn-secondary-border:              @brand-secondary;
@btn-secondary-beforecolor:         darken(@brand-secondary,40%);

@btn-white-color:               #000;
@btn-white-line-color:          #fff;
@btn-white-bg:                  #fff;
@btn-white-border:              #fff;

@btn-black-color:               #fff;
@btn-black-line-color:          #000;
@btn-black-bg:                  #000;
@btn-black-border:              #000;

@btn-line-bg: transparent;
@btn-line-width: 1px;




@btn-padding-base-vertical:     .5em;
@btn-padding-base-horizontal:   3em;
@button-correction: true;
@btn-bottom-correction: .5em;
@btn-top-correction: .65em;

@letter-spacing-correction: -0em;
@tex-indent-correction: -.05em;


body *::selection {
  background:@brand-primary;
  color: #fff;
}

@blockquote-small-color:      @brand-gray-dark;
//** Blockquote font size
@blockquote-font-size:        (@font-size-base * 1.125);
//** Blockquote border color
@blockquote-border-color:     @brand-secondary;




.pad-fixer (@btn-correction) when (@button-correction = true ){
  padding-bottom: @btn-bottom-correction;
  padding-top: @btn-top-correction;
}