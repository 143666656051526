.border-right {
   border-right: 1px solid @brand-primary;

  &:last-child {
    border-right: 0px;
  }
   @media( max-width: @screen-md-min){
     border-bottom: 1px solid;
     border-right:0px;
     padding-top:15px;
     padding-bottom:15px;
     &:last-child {
       border-bottom: 0px;
     }
   }
 }
.border-left {
  border-left: 1px solid @brand-primary;
  &:last-child {
    border-left: 0px;
  }
  @media( max-width: @screen-md-min){
    border-top: 1px solid;
    border-left:0px;
    padding-top:15px;
    padding-bottom:15px;
    &:last-child {
      border-top: 0px;
    }
  }
}