footer {

  display: block;
  float: left;
  position: relative;
  width: 100%;
  //font-family: @headings-font-family;
  //font-size:1.285em;
  //line-height: 1.2em;
  ul {
    padding-left: 0px;
    list-style: none;
  }
  .center-footer {
    .pad-md-vert();
    //.bg-variants(@footer-color-1, @footer-text, @footer-text);
    ul {
      margin-bottom: 0px;
      li {
        font-weight: 600;
        font-family: @headings-font-family;
        margin-bottom:1em;

        a {
          display: inline-block;
          width: 100%;
          padding:0em;
          margin-bottom: .5em;
          padding-bottom:.5em;
          border-bottom: 1px solid @brand-primary;
          &:hover { text-decoration: none; }
        }
        ul {
          margin-bottom: 0px;
          font-size: .8em;
          text-transform: none;
          li {

            font-family: @font-family-base;
            margin-bottom: 0em;
            a {
              color: #aaa;
              margin-bottom: 0em;
              border-bottom: 0px solid transparent;
              padding: .125em 0em;
              font-style: italic;
              font-weight: 400;
              border-bottom: 0px solid transparent;
            }
          }
        }
      }
    }

    a {
      color: inherit;
      &:hover {
        color: @footer-hover;
      }
    }
    @media (max-width: @screen-xs-max) {
      text-align: center;
      .footer-widget-area {
        .widget_nav_menu {
          padding-bottom: .0em;
          padding-top: 0em;
          padding-left: 50px;
          padding-right: 50px;
          //border-bottom: 1px solid #000;
          ul {
            li {
              margin-top: .5em;
              margin-bottom: .5em;
            }
          }
        }
      }
    }
    //@media (max-width: @screen-md-min) {
    //  display: none;
    //}
  }
  .logo-link #footer-logo {
    //height: 96px;
    //width: 175px;
    height: @footer-logo-height;
    width: @footer-logo-width;

    position: relative;
    background: @footer-logo-fallback, @footer-logo-color-fallback;
    background: @footer-logo, @footer-logo-color-fallback;
    background-size: cover;
    display: block;

  }

  @media ( min-width: @screen-lg-min) {
    .logo-container { width: auto; }
  }

  @media ( max-width: @screen-md-min) {
    .logo-link { display: inline-block; }
    .logo-container {
      text-align: center;
      float: left;
      width: 100%;
    }
  }

  .after-footer {
    .pad-xs-vert();
    .bg-variants(@footer-color-1, @footer-text, @footer-text);
    border-top: 1px solid @footer-text;
    font-size: .6em;
    font-weight: 500;
    text-transform: uppercase;
    font-family: @font-family-base;
    i, span.b-icon {
      color: @footer-color-1;
    }
    a {
      color: inherit;
      &:hover {
        color: @footer-hover;
      }
    }

    .col-2 {
      text-align: right;
    }

  }
  .logo-link + p {
    line-height:1.5em;
    margin-bottom:1.5em;
    font-size:1em;
    font-family: @font-family-base;
    color: #aaa;

  }
}
