
.live-area {
  border-color: @brand-primary;
}

.text-icon-cols {
  padding-top: 50px;
  padding-bottom: 50px;
  @media ( min-widtH: @screen-md-min ) {
    .pad-sm-vert();
  }
  .column {
    padding-left: 30px;
    padding-right: 30px;
    @media (min-width: @screen-sm-max) {
      &:first-child {
        padding-left: 15px;

      }

      &:last-child {
        padding-right: 15px
      }
    }
  }
  .bg-cover();

  .text-col {
    .pad-xs-vert();

  }
  .icon-col {
    .pad-xs-vert();
  }
}

.text-img-cols {
  .img-col {

    display: flex;
    align-items: flex-end;

  }
  .text-col {

    display: flex;
    align-items: center;

  }
}

.split-cta {

  .text-col {
    .border-right();

    .pad-xs-vert();
  }
  @media (min-width: @screen-md-min) {
    .text-col {
      .pad-sm-horz();
    }

    .text-col:first-child {
      padding-left: (@grid-gutter-width /2)
    }

    .text-col:last-child {
      border-right: 0px;
      padding-right: (@grid-gutter-width /2)
    }
  }
}

.featured-image {

}

.post-listing {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .post-edit-link {
    position: absolute;
    z-index: 99;
    top: 0;
    right: 0;
    padding: 5px 10px;
    background: @brand-primary;
    color: @brand-text-on-dark;
  }
  .feat-img {
    display: inline-block;
    float: left;
    width: 25%;
    height: auto;
    + .article-obj {
      float: left;
      padding-left: @grid-gutter-width;
      display: inline-block;
      width: 75%;
    }
  }
  .article-obj {
    float: left;
    padding-left: @grid-gutter-width;
    display: inline-block;
    width: 100%;
  }
}

.single-post {
  > .featured-image {
    max-width: 320px;
    height: auto;
    display: inline-block;
    float: left;
    margin-right: @grid-gutter-width;
    margin-bottom: @grid-gutter-width;
  }
}

.t-primary {
  color: @brand-primary;
  p {
    color: inherit
  }
  h1, h2, h3, h4, h5, h6 {
    color: inherit
  }

}

.t-secondary {
  color: @brand-secondary;
  p {
    color: inherit;
  }
  h1, h2, h3, h4, h5, h6 {
    color: inherit;
  }
}

.gallery-col {
  text-align: center;

}

iframe {

  max-width: 100%;
}

@media (max-width: @screen-md-min ) {
  .md-flex-row-center, .md-flex-row-stretch {
    .column ~ .column {
      margin-top: 15px;
      &.border-right {
        margin-top: 0px;
      }
    }
  }

}

.hr-row {

  .column {
    border-color: @brand-primary;
    padding-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid @brand-primary;
    @media (min-width: @screen-md-min) {
      margin-bottom: 25px;
      padding-top: 25px;

    }
  }
}

.column {
  .hr-row {
    border-color: @brand-primary;
    padding-top: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid @brand-primary;
    @media (min-width: @screen-md-min) {
      margin-bottom: 25px;
      padding-top: 25px;
    }
  }
}

#top-nav ul.nav {

  li.btn-primary {
    a {
      &:hover {
        color: @brand-primary;
      }
    }
  }
}

.row.bordered-columns {
  .column {
    border-right: 3px solid @brand-primary;
    padding-top: (@grid-gutter-width * .5);
    padding-bottom: (@grid-gutter-width * .5);
    &:last-child {
      border-right: 0px;

    }
  }
}

.faq-section {
  .bg-gray();
  .pad-md-vert();
  .border-bottom-strong();
  .text-xl();
  .row.md-flex-row-stretch {
    margin-top: 1em;
    .column {
      .md-flex-row-center();
      a {
        color: #fff;
        &:hover {
          color: @brand-primary;
          text-decoration: none;
        }
      }

    }

  }
  @media ( max-width: @screen-sm-max) {
    .column h2 {
      font-size : 1.25em;
    }
    .row.md-flex-row-stretch {
      display: flex;
      align-items: stretch;
      flex-wrap: wrap;
      font-size: 16px;
      line-height: 1.71;
      .column {
        width: 50%;
        border-right: 0px solid;
        margin-top: .5em;
        margin-bottom: .5em;
      }
      .column:nth-child(even) {
        border-left: 3px solid @brand-primary;
      }
    }
  }
}

.live-area article .checkmark-list {
  ul {
    list-style-type: none;
    li {
      padding-left: 2.5em;
      position: relative;
      margin-top: 1em;
      margin-bottom: 1em;
      &:before {
        display: inline-block;
        position: absolute;
        height: 2em;
        width: 2em;
        left: 0px;
        content: '\f00c';
        top: 0;
        margin-top: -0.1250em;
        line-height: 2em;
        font-size: 1em;
        text-align: center;

        font-family: 'FontAwesome';
        color: @brand-primary;
        border: 1.5px solid @brand-gray-dark;
        border-radius: 50%;
      }
    }
  }
}

section.we-strive {
  @media ( max-width: @screen-sm-max) {
    .column h2 {
      font-size : 1.5em;
    }
  }
  .checkmark-list ul {
    width: 100%;

    li {
      display: inline-block;
      padding-right: 30px;
      box-sizing: border-box;
      width: 49%;
      vertical-align: top;
    }
    @media (max-width: @screen-xs-max) {
      li {
        width: 100%;
      }
    }
  }
}
section.cust-testimonials {
  .bg-gray-light();
  //.text-lg();
  .pad-md-vert();
  text-align: center;
  div.row.cust-testimon {
    text-align: center;
    .column div {
      .md-flex-row-center();
      padding-top: 1em;
      blockquote {
        border-left: 0px solid transparent;
        display: block;
        float: left;
        padding: 30px;

        p {
          margin-bottom: 0px;
        }
        margin-bottom: .5em;
        margin-top: .5em;
        width: 100%;
        @media (min-width: @screen-sm-max) {
          width: 33%;
        }

      }
    }
  }

  @media(max-width: @screen-sm-max) {
    .column h2{font-size: 1.25em;}
    div.row.cust-testimon {
      .column div blockquote{ padding: 15px;}
    }
  }
}

.column.square-image {
  &:before {
    content: '';
    width: 100%;
    display: block;
    height: auto;
    padding-bottom: 100%;
    background: inherit;

  }
}

section.about-gudeman {
  .pad-lg-vert();
}

section.what-gudeman-can-do {
  .row.column {
    @media (max-width @screen-md-min) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
}

section.landing-header {

  .column.column-landing {
    .btn {
      padding-left: 1em;
      padding-right: 1em;
    }
    .text-lg();
    h1 {
      font-size: 4em;
    }
    border-left: 15px solid @brand-primary;
    padding: (2*@grid-gutter-width) (@grid-gutter-width*1) (2*@grid-gutter-width) (@grid-gutter-width * 1 );

  }
  background-position: right center;
  background-size: cover;
  @media (max-width: @screen-md-max) {
    background-position: 84% 7%;

    background-size: cover;
    .column.column-landing {
      width: 50%;
    }
  }

  @media (max-width: @screen-sm-max) {
    background-position: 84% 7%;
    background-size: auto 147%;
    &:before {
      display: block;
      width: 100%;
      height: 100%;
      content: '';
      background: @brand-gray-dark;
      position:absolute;
      left: 0;
      top: 0;

    }
    .column.column-landing h1 {
      font-size: 3.25em;
    }

    .column.column-landing {
      width: 70%;
    }
  }
  @media (max-width: @screen-sm-min) {
    background-position: 84% 7%;
    background-size: auto 147%;
    .column.column-landing h1 {
      font-size: 2.25em;

    }.column.column-landing {
      width: 100%;

    }
  }

}

.landing-page.modern-cta {
  .row {
    .column {
      @media (min-width: @screen-md-min) {
        &.text-col {
          font-size: 1.75em;
        }

        &.btn-col {
          text-align: center;
          font-size: 1.5em;
        }
      }

      @media (max-width: @screen-md-min) {
        &.text-col {
          text-align:center;
          font-size: 1.25em;
        }

        &.btn-col {
          text-align: center;
          font-size: 1em;
        }
      }
    }
  }
}

.landing-page.take-first-step {
  .row {
    .column {
      @media (max-width: @screen-md-min) {
        font-size: 1.25em;
        h2{
          text-align:center;
          font-size: 1.5em;
        }

        a {

          text-align: center;
          font-size: 1em;
        }
      }
    }
  }
}





















#scrollLockModal {
  display: block;
  position: fixed;
  width: 100%;
  height: 0;
  z-index: 300;
  top: 0;
  left: 0;
  background: #fff;
  opacity: .0;
  cursor: pointer;
  visibility: hidden;
  transition: opacity 0.3s;
  transition-timing-function: ease-in-out;
  &.scrollLock {
    opacity: .35;
    height: 100%;

    visibility: visible;
  }
}

#cta-modal {
  border: 1px solid @brand-gray-light;
  box-shadow: 0px 4px 4px 3px fade(@brand-gray-dark, 25);
  @media (max-width: @screen-sm-max) {
    width: 100%;
  }

  // ACTUAL MODAL PROPERTIES -------------------------
  position: fixed;
  max-height: 100%;
  visibility: hidden;
  overflow-y: auto;
  vertical-align: middle;
  display: block;
  top: -500000%;
  left: 50%;
  transform: translate(-50%) translateY(-50%) scale(0, 0);
  z-index: 301;
  padding-top: 0px;
  padding-bottom: 0px;
  transition: transform 0.3s;
  transition-timing-function: ease-in-out;
  background: #fff;
  &.open {
    visibility: visible;
    top: 50%;
    transform: translate(-50%) translateY(-50%) scale(1, 1);
    display: block;

  }

  #closeModal {

    font-size: 1em;
    //background: @brand-secondary;
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.6em 0.8em;
    z-index: 22;
    text-align: center;
    background: @brand-primary;
    color: #fff;

    i {
      width: 1em;
      height: 1em;
      color: inherit;
    }
  }
}

#cta-modal {
  .row.content-row-cta-modal {
    @media (min-width: @screen-xs-max) {
      display: flex;
      align-items: stretch;
    }
    .column.form-column {
      .bg-gray-light();
      background: lighten(@brand-gray-light, 5%);
    }
  }
  @media (max-width: @screen-xs-max) {
    width: ~'calc(100% - 40px)';
  }
  .row.content-row-cta-modal {
    .column {
      padding-top: 25px;
      padding-bottom: 25px;
    }
  }
}

#cta-modal {
  form {
    div.validation_error {
      display: none !important;
    }
  }
  form .gform_body {
    ul.gform_fields {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      li.name-field {
        width: 33%;
      }
      li.phone-field {
        width: 33%;
      }
      li.email-field {
        width: 33%;
      }
      li.dropdown-field {
        width: 100%;
      }
      li.message-field {
        width: 100%;
      }

      li.name-field,
      li.phone-field,
      li.email-field,
      li.dropdown-field,
      li.message-field {
        input, textarea, select {
          width: 100%;
          background: @brand-gray-light;
          border: 1px solid @text-color !important;
        }
      }
      @media (max-width: @screen-sm-max) {
        li.name-field {
          width: 100%;
        }

        li.phone-field {
          width: 50%;
        }

        li.email-field {
          width: 50%;
        }

        li.dropdown-field {
          width: 100%;
        }

        li.message-field {
          width: 100%;
        }
      }
      @media (max-width: @screen-xs-max) {
        li.name-field {
          width: 100%;
        }

        li.phone-field {
          width: 100%;
        }

        li.email-field {
          width: 100%;
        }

        li.dropdown-field {
          width: 100%;
        }

        li.message-field {
          width: 100%;
        }
      }
    }
  }
}

#cta-modal {
  h2.modal-title {
    span {
      font-weight: 400;
    }
    margin-bottom: 0px;
  }
  a.btn.btn-sm.btn-primary {
    padding-left: 1em;
    padding-right: 1em;
    display: inline-block;
    max-width: 100%;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .column-contact {
    position: relative;
    &:before {
      @media (min-width: @screen-xs-max) {
        display: block;
        width: 1px;
        content: '';
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-left: 1px solid @brand-primary;
      }
    }
    .hours-details {
      @media(max-width: @screen-sm-min) {
        padding: 15px;
      }
    }
  }
  #cta-logo {
    display: inline-block;
    max-width: 320px;
    width: 100%;
    position: relative;
    background: @footer-logo-fallback, @footer-logo-color-fallback;
    background: @footer-logo, @footer-logo-color-fallback;
    background-size: cover;
    display: block;
    &:before {
      content: '';
      background: inherit;
      padding-bottom: 39.857%;
      background-size: cover;
      display: inline-block;
      width: 100%;
    }
  }
}

.heading-wrap-sidebar-page-alt {

  @media (min-width: @screen-sm-max) {
    font-size: 1.5em;
  }
  @media (max-width: @screen-sm-max) {
    font-size: 1.25em;
  }
  @media (max-width: @screen-xs-max) {
    font-size: 1.025em;
  }
  h1 {
    margin-top: 0px;
    word-break: break-word;
    hyphens: auto;
    &:only-child {
      margin-bottom: 0px;
    }
  }
  p, strong {
    line-height: @line-height-base;
  }
  .heading-content {
  }
}

.row.page-sidebar-row #sidebar.sidebar-alternate {
  @media (max-width: @screen-sm-max) {

    order: 3;
  }
}

article.sidebar-alt {
  #content.sidebar-content {
    padding-left: (@grid-gutter-width*.5);
    padding-right: (@grid-gutter-width*.5);
    section {
      .row();
    }
  }
}