.live-area #content .arconix-faq-wrap {
  padding: 0px 0px 0px 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 1em;
  padding-bottom: 1em;
  border-radius: 0px;
  border: 0px solid transparent;
  background: inherit;
  .arconix-faq-title {
    font-size: 2em;
    text-transform: uppercase;
    font-family: @headings-font-family;
    line-height: @headings-line-height;
    font-weight: @headings-font-weight;
    background: inherit;
    padding: 0 0 0 0;
    &.faq-open {
      padding-bottom: 5px;
      margin-bottom: 5px;
      border-bottom: 1px solid @brand-primary;

    }
  }
}