//
//
//
//
//
////---- BICON FONT ---------------------------------------------------------
////-------------------------------------------------------------------------
////-------------------------------------------------------------------------
//
//
@font-face{
  font-family:"ICO";
  src:url("../fonts/icomoon.eot?#iefix");
  src:url("../fonts/icomoon.eot?#iefix") format("eot"),url("../fonts/icomoon.woff") format("woff"),url("../fonts/icomoon.ttf") format("truetype"),url("../fonts/icomoon.svg") format("svg");
}
//
////-------------------------------------------------------------------------
////-------------------------------------------------------------------------
//
//
//@font-face{
//  //font-family:"Helvetica Neue LT W01_55 Roman";
//
//  src:url("../fonts/FontsCom/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
//  src:url("../fonts/FontsCom/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"),url("../fonts/FontsCom/3dac71eb-afa7-4c80-97f0-599202772905.woff2") format("woff2"),url("../fonts/FontsCom/34e0e4c0-c294-49bb-9a8e-1b2cafd54e32.woff") format("woff"),url("../fonts/FontsCom/8a8bfee3-197c-4942-9b11-71508cc9f406.ttf") format("truetype"),url("../fonts/FontsCom/5101995a-e73b-4cf9-84e8-f99eb43277b1.svg#5101995a-e73b-4cf9-84e8-f99eb43277b1") format("svg");
//  font-family:"Helvetica Body";
//  font-style: normal;
//  font-weight: 400;
//}
//@font-face{
//  //font-family:"Helvetica Neue LT W01_85 Heavy";
//
//  src:url("../fonts/FontsCom/b7066775-c685-4630-b98c-5da67d4981e9.eot?#iefix");
//  src:url("../fonts/FontsCom/b7066775-c685-4630-b98c-5da67d4981e9.eot?#iefix") format("eot"),url("../fonts/FontsCom/dd7814bd-8abf-46c5-a4be-db0977de2cca.woff2") format("woff2"),url("../fonts/FontsCom/20ffa4b4-0154-4ca6-800c-468cecbd60f1.woff") format("woff"),url("../fonts/FontsCom/c68e9fac-92b0-47be-853c-1a0e92f68c3d.ttf") format("truetype"),url("../fonts/FontsCom/94d5af98-e327-4660-89da-a0c66d6fdfe9.svg#94d5af98-e327-4660-89da-a0c66d6fdfe9") format("svg");
//  font-family:"Helvetica Body";
//  font-weight: 600;
//}
//@font-face{
//  //font-family:"Helvetica Neue LT W01_87 Hv Cn";
//  src:url("../fonts/FontsCom/dfe2df2c-9e8e-489d-b572-0f24043e9ca1.eot?#iefix");
//  src:url("../fonts/FontsCom/dfe2df2c-9e8e-489d-b572-0f24043e9ca1.eot?#iefix") format("eot"),url("../fonts/FontsCom/835c9867-49fd-49c6-ad61-f6e4e47e17fa.woff2") format("woff2"),url("../fonts/FontsCom/0c4dfc49-f798-458f-be90-e1d5a3e65f48.woff") format("woff"),url("../fonts/FontsCom/f4ad3cac-3a29-41f8-b890-e7badf717aa0.ttf") format("truetype"),url("../fonts/FontsCom/c1ce2e4a-f726-4fea-89b2-7e0896c07a06.svg#c1ce2e4a-f726-4fea-89b2-7e0896c07a06") format("svg");
//  font-family:"Helvetica Body";
//  font-weight: 800;
//}
//@font-face{
//  //font-family:"Helvetica Neue LT W0167MdCnObl";
//
//  src:url("../fonts/FontsCom/9e97b244-e222-4613-ab0b-a764773596a3.eot?#iefix");
//  src:url("../fonts/FontsCom/9e97b244-e222-4613-ab0b-a764773596a3.eot?#iefix") format("eot"),url("../fonts/FontsCom/4ed8935f-c60a-4861-aa65-33dc913e917b.woff2") format("woff2"),url("../fonts/FontsCom/9604fe50-0916-42ef-aa87-db805fc07cf7.woff") format("woff"),url("../fonts/FontsCom/b59745b6-71dc-42e5-ab27-7b341eb6506b.ttf") format("truetype"),url("../fonts/FontsCom/968470de-55d3-4720-add7-7994bba95e9c.svg#968470de-55d3-4720-add7-7994bba95e9c") format("svg");
//  font-family:"Helvetica Body";
//  font-style: italic;
//}
//@font-face{
//  //font-family:"Helvetica Neue LT W0167MdCnObl";
//
//  src:url("../fonts/FontsCom/9e97b244-e222-4613-ab0b-a764773596a3.eot?#iefix");
//  src:url("../fonts/FontsCom/9e97b244-e222-4613-ab0b-a764773596a3.eot?#iefix") format("eot"),url("../fonts/FontsCom/4ed8935f-c60a-4861-aa65-33dc913e917b.woff2") format("woff2"),url("../fonts/FontsCom/9604fe50-0916-42ef-aa87-db805fc07cf7.woff") format("woff"),url("../fonts/FontsCom/b59745b6-71dc-42e5-ab27-7b341eb6506b.ttf") format("truetype"),url("../fonts/FontsCom/968470de-55d3-4720-add7-7994bba95e9c.svg#968470de-55d3-4720-add7-7994bba95e9c") format("svg");
//  font-family:"Helvetica Heading";
//  font-weight: 400;
//}
//@font-face{
//  //font-family:"Helvetica Neue LT W0177BdCnObl";
//
//  src:url("../fonts/FontsCom/7560f08f-e627-461f-acba-c2fc820c6f0c.eot?#iefix");
//  src:url("../fonts/FontsCom/7560f08f-e627-461f-acba-c2fc820c6f0c.eot?#iefix") format("eot"),url("../fonts/FontsCom/a2957f2d-9b3b-4aea-b86c-bba81fc8128e.woff2") format("woff2"),url("../fonts/FontsCom/879d541c-7f90-4fe6-93c4-453ee22fa15e.woff") format("woff"),url("../fonts/FontsCom/75f88dce-dde4-429c-92d1-1a1631b021a8.ttf") format("truetype"),url("../fonts/FontsCom/164f7c14-41fa-4682-9997-b8f0841b7666.svg#164f7c14-41fa-4682-9997-b8f0841b7666") format("svg");
//  font-family:"Helvetica Heading";
//  font-weight: 700;
//}
//@font-face{
//  //font-family:"Helvetica Neue LT W0187HvCnObl";
//
//  src:url("../fonts/FontsCom/1a4c2095-2750-49bd-8f7d-cd6c592afd44.eot?#iefix");
//  src:url("../fonts/FontsCom/1a4c2095-2750-49bd-8f7d-cd6c592afd44.eot?#iefix") format("eot"),url("../fonts/FontsCom/553e628a-a8fd-435f-bb45-83e721924cf9.woff2") format("woff2"),url("../fonts/FontsCom/2dc6275e-59c0-4e63-849f-4c0261be9817.woff") format("woff"),url("../fonts/FontsCom/07c1c0ff-91b8-4163-98a7-87c9ceea9ed5.ttf") format("truetype"),url("../fonts/FontsCom/e15c4e28-fd35-43bd-ba61-eaaa65e47dc7.svg#e15c4e28-fd35-43bd-ba61-eaaa65e47dc7") format("svg");
//  font-family:"Helvetica Heading";
//  font-weight: 800;
//}
