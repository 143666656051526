
//  Inherent Z-INDEX FIXES
//---------------------------------------------------------
    #wrap { z-index: 1; }




@media screen and (max-width: 782px) {
  //html #wpadminbar {
  //  display:none;
  //  }
  //html {
  //  position:relative;
  //  margin-top: 46px !important;
  //  }
  //body {
  //  position:relative;
  //  }
  }


//  Default Menu Occlusion
//---------------------------------------------------------

    @media(max-width: @screen-md-min){ #main-nav #navbar { display: none !important; }    #main-nav {background: @menu-background;} }
    @media(min-width: @screen-md-min){ .mobile-only { display: none !important; } }
    #mobile-menu-container, #mobile-trigger { @media(min-width: @screen-md-min){ display:none !important; } }


//  Trigger button styling | onClick Event Attached
//---------------------------------------------------------

      #mobile-trigger {
        position: fixed;
        top: 9px;
        right: 20px;

        font-family:@headings-font-family;
        overflow:hidden;
        white-space: nowrap;
        font-size: 16px;
        background: #fff;
        color: @menu-color;
        background: @menu-background;
        padding: 17px;
        line-height:14px !important;
        z-index: 288;
        transform: translateZ(0);


        // align button to bootstrap margin when container is not 100% wide
        @media(min-width: @screen-sm-min) {
          position:fixed;
          right: calc(~"(((100% - 740px) * .5) + 15px)") !important;
        }
        &.open {
          // open class for animation of button context
        }
      }


//  Scroll Busting | scroll lock div is overlay
//---------------------------------------------------------

      #scrollLock {
          display:block;
          position:fixed;
          width:100%;
          height:0;
          z-index:200;
          top:0;
          left:0;
          background:@scrollLockColor;
          opacity: .0;
          visibility: hidden;
          transition: opacity @transTime;
          &.scrollLock {
            opacity: .75;
            height:100%;
            visibility: visible;
          }
      }

      //#scrollLockModal {
      //  display:block;
      //  position:fixed;
      //  width:100%;
      //  height:0;
      //  z-index:400;
      //  top:0;
      //  left:0;
      //  background:#000;
      //  opacity: .0;
      //  visibility: hidden;
      //  transition: opacity @transTime;
      //  &.scrollLock {
      //    opacity: .75;
      //    height:100%;
      //    visibility: visible;
      //  }
      //}

      body.scrollLock {
        overflow:hidden;
        min-height:5000%;
        width:100%;
        position:fixed;
        top:0;
        left:0px;
        #wrap {
          #page-header,#header-content,#page-container, div.row.bf-cta, footer,#copyright {
            //-webkit-filter: blur(5px);
            //-moz-filter: blur(5px);
            //-ms-filter: blur(5px);
            //-o-filter: blur(5px);
            //filter: blur(5px);
          }
        }
       }




//  MENU OBJECT
//---------------------------------------------------------

      .mobile-menu {
        font-family:@headings-font-family;
        display: block;
        width: 300px;
        height: 100%;
        max-height: 100%;
        position: fixed;
        top: 0px;
        right: -300px;
        z-index: 300;
        transition: transform @transTime, right @transTime;
        line-height: .95em;
        font-size:1em;
        background: @menu-background;
        transform: translateZ(0);
        &.open { right: 0px;}

        //  Heading class
        //---------------------------------------------------------
        .submenuHeading {
          width: 100%;
          color:@brand-primary;
          padding: @mobile-item-padding;
          background: darken(@menu-background,10%);
          display: inline-block;
          text-transform: uppercase;

          font-weight: 900;
          font-family: @headings-font-family;
          border-bottom:1px solid darken(@menu-color , 10%);
        }

        //  Nav Object
        //---------------------------------------------------------
        nav#mobile-menu-nav {
          height: 100%;
          max-height: 100%;
          width: 100%;
          overflow-x: hidden;
          overflow-y: auto;
          border-left: 2px solid @menu-color;
          ul.menu {

            list-style: none;
            margin: 0px 0px 0px 0px;
            padding:0px;
            li {
              padding-left: (@mobile-item-padding *2.5);
              padding-right: (@mobile-item-padding *1);
              position: relative;
              border-bottom: 1px solid darken(@menu-color , 10%);
              padding-top: .125em;
              padding-bottom: .125em;
              vertical-align: middle;
              &.menu-item-has-children {
                position: relative;
              }
              a {
                color: @menu-color;
                padding-top: @mobile-item-padding;
                padding-bottom: @mobile-item-padding;
                line-height: 1em;
                width: 100%;
                display: inline-block;
                //text-transform: uppercase;
                vertical-align: bottom;
                font-weight: 100;
                font-family: @headings-font-family;
                i {
                  font-size: .8em;
                  margin-left: (@mobile-item-padding * -1.25);
                  margin-right: (@mobile-item-padding * 1.25);
                  margin-bottom:0px;
                  &.fa {
                    font-size:1em;
                  }
                }

              }
              &.minor a {
                font-weight: 100;
              }
              a.main-item {
                  width:225px;
                  + a.submenulink {
                    width:40px;
                    border-left: 1px dotted @menu-color;
                    padding-left: 10px;
                    text-align: center;
                    cursor: pointer;
                    i {
                      float:none !important;
                      margin:0px 0px 0px 0px;
                    }
                  }
                }
              a.submenulink.open + ul.sub-menu{ right:0px;}

              //  First Submenu Leve
              //---------------------------------------------------------
              ul.sub-menu {
                padding-left:0px;
                padding-right:0px;
                list-style: none;
                margin: 0px 0px 0px 0px;
                position:fixed;
                top:0px;
                text-align:left;
                width:280px;
                right:-290px;
                border-left: 2px solid @menu-color;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                background: @menu-background;

                transform: translateZ(0);
                transition: transform @transTime, right @transTime;
                z-index:400;
                li {

                  a {
                    &.submenulink{
                      text-align: center;

                      i {
                        margin-left:0px;
                        margin-right:0px;
                        font-size:1em;
                      }
                    }
                  }
                  ul.sub-menu {
                    padding-left:0px;
                    padding-right:0px;
                    list-style: none;
                    margin: 0px 0px 0px 0px;
                    position:fixed;
                    top:0px;
                    width:260px;
                    right:-270px;
                    border-left: 2px solid @menu-color;
                    height: 100%;
                    overflow-x: hidden;
                    overflow-y: auto;
                    background: @menu-background;

                    transform: translateZ(0);
                    transition: transform @transTime, right @transTime;
                    z-index:400;
                    li {

                      a { }
                    }
                  }
                }
              }
            }
          }
        }
        // END NAV OBJECT

        //  Inner Trigger Objects
        //---------------------------------------------------------
         #mobile-trigger-inside,
         a.submenulink.back,
         a.submenulink {
           display:inline-block;
           color: @menu-color;
           background: @menu-background;
           width:100%;
           padding: @mobile-item-padding 0;
           box-sizing: border-box;
           i {
             margin-left:0px;
            margin-right:0px;
           }

         }
        #mobile-trigger-inside,
        a.submenulink.submenuback {
          border-bottom: 1px solid @menu-color;
          height:40px;
          text-align:right !important;
          font-weight:100;
          width: 100%;
          padding: @mobile-item-padding;
          line-height: 1.25em;

          i {
            float:left;
            font-size:1em !important;
            margin-left:0px !important;
            margin-right:0px !important;

          }
        }
         nav#mobile-menu-nav ul.menu li a.submenulink.half-menu-item { width: 50px; position:absolute; right:0; background: transparent; color: @menu-color; top: 0; }
         //nav#mobile-menu-nav ul.menu li a.submenulink.submenuback { width: 100%; text-align:left; color: @menu-color; padding: @mobile-item-padding;}
        nav#mobile-menu-nav ul.menu li a.submenulink {
          &.main-item {
            font-weight: 100;
            color: @menu-color;
            background: @menu-background;
            vertical-align:bottom;
          }
        }

      }
      #mobile-menu-nav > ul.menu > li   {
        padding-top:1px;

          position:relative;
          &:before {
            display:none;
          }

      }

      .mobile-menu nav#mobile-menu-nav  > ul.menu > li > ul.sub-menu > li.link-row {
        span { margin-top:.5em; display:inline-block;}
        ul.sub-menu {
          z-index: 1;
          display: inline-block;
          position: relative;
          width: 100%;
          color: #000;
          left: 0;
          top:0;
          border-left: 0px;

          li {
            border-bottom:0px;
            width: auto;
            float: left;
            padding-left: .25em;
            padding-right: .25em
          }
          li a {padding-left:0px; padding-right:0px; font-size: .75em;}
          a.submenulink.submenuback { display:none; }

        }
      }
      // End Mobile Menu Markup



//  CSS 3 OVERRIDES FOR SMOOTH ANIMATION ( overrights RIGHT: PX POSITIONING)
//---------------------------------------------------------

      body.css3-animations-enabled {
        .mobile-menu {
          transform: translateX(0px);
          &.open {
            right: -300px;
            transform: translateX(-300px);
            a.submenulink + ul.sub-menu{ transform: translateX(0px); right: -300px;}
            a.submenulink.open + ul.sub-menu{ transform: translateX(-300px);}
          }
        }
        .mobile-menu nav#mobile-menu-nav ul.menu li a.submenulink + ul.sub-menu{ transform: translateX(0px); right: -300px;}
        .mobile-menu nav#mobile-menu-nav ul.menu li a.submenulink.open + ul.sub-menu{ transform: translateX(-300px);}
      }


      #top-nav {
        @media ( max-width: @screen-xs-max) {

        }
        @media ( max-width: @screen-sm-max) {
         display: none;
        }
      }





//
//// MODAL HANDLING
////---------------------------------------------------------
//
//  #cta-modal {
//    @media(max-width: @screen-sm-max) {
//      width:100%;
//    }
//    .button-row {
//      @media(max-width: @screen-sm-min) { font-size: .5em;}
//      @media (min-width: @screen-sm-min) { position:absolute; width:100%;}
//    }
//    .logo-inline-image {
//      width:100%;
//      padding-top:100%;
//      background-size: 80% !important;
//      background-position: center center !important;;
//      background-repeat:no-repeat !important;;
//
//    }
//    .center-row {
//      @media (min-width: @screen-sm-min) {padding:1em 0 ;}
//      @media (max-width: @screen-sm-min) {padding:0 0 1em 0 ;}
//      display:flex;
//      align-items: center;
//    }
//    #headline {
//      font-size:1em;
//      @media( max-width: @screen-sm-min ) {padding-left:0px;}
//      @media (min-width: @screen-sm-min) { font-size:2em; }
//      @media (min-width: @screen-md-min) { font-size:2.5em; }
//      @media (min-width: @screen-lg-min) { font-size:3em; }
//    }
//    hr {
//      color: @brand-black;
//      margin-top:0px;
//      margin-bottom:0px;
//      border-top: 1px solid @brand-black;
//    }
//
//    .content-row {
//      padding: .5em;
//      @media(min-width:@screen-md-min){
//        padding-left:1em; padding-right:1em;
//        .ex-col { padding-left:(@grid-gutter-width *.5); }
//        }
//      display:flex; align-items: stretch;
//      flex-wrap: wrap;
//      .column {
//        padding-top:2em;
//        padding-bottom: 2em;
//        border-right: 1px solid @brand-black;
//        width:40%;
//        &:nth-child(2){ border-right: 0px; width:60%}
//        .row {
//          @media(min-width:@screen-sm-max) {
//            padding-left:(@grid-gutter-width *.5);
//            padding-right:(@grid-gutter-width);
//            }
//          }
//        .inner-col {
//          @media(min-width:@screen-sm-max) {
//            &:nth-child(1) {padding-top:0px;}
//            &:nth-child(2) {padding-bottom:0px;}
//            &:nth-child(2) {border-top:1px solid @brand-black;}
//            }
//          @media(max-width:@screen-xs-max) {
//            &:nth-child(2) {border-top:1px solid @brand-black;}
//            }
//          }
//      }
//      @media (max-width: @screen-xs-max) {
//        .column {
//          width: 100%;
//          border-top:1px solid @brand-black;
//          border-right:0px solid;
//          &:nth-child(1){ border-top: 0px; padding-top:0px; padding-bottom:0px;}
//          &:nth-child(2){ width:100%;}
//        }
//      }
//      @media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
//        .column {
//          width: 100%;
//          border-top:0px solid @brand-black;
//          &:nth-child(1){ border-right: 0px solid;
//              .inner-col{
//                &:nth-child(1){ border-right: 1px solid @brand-black;
//                }
//              }
//            }
//          &:nth-child(2){
//
//            border-top:1px solid @brand-black;
//            width:100%;
//          }
//        }
//      }
//    }
//
//    .btn.btn-default {
//      margin-top:0px;
//    }
//    .gform_wrapper ul li.gfield.field_sublabel_above.field_description_below+li.gfield {
//     min-height:0px;
//    }
//
//
//
//
//    // ACTUAL MODAL PROPERTIES -------------------------
//    .bg-primary();
//    position:fixed;
//    max-height:100%;
//    overflow-y:auto;
//    vertical-align:middle;
//    display:none;
//    top: 50%;
//    left: 50%;
//    transform: translate(-50%) translateY(-50%);
//    z-index:410;
//
//    &.open {
//      display:block;
//    }
//
//    #closeModal {
//      font-size:2em;
//      .bg-black();
//      display:inline-block;
//      padding:0.5em;
//      text-align:center;
//      color: @brand-primary ;
//      i { width: 1em; height:1em;}
//    }
//  }



@media(max-width: @screen-sm-max) {
  body.logged-in {
    &.scrollLock {
      top : 46px !important;
      #wpadminbar {
        display : none;
        }
      }
    #mobile-trigger, #mobile-menu-container {
      top : 46px;
      }
    }
  }

.store-launch {
  display: none !important;
  }